.event-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.event-page .event-container {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
}

.event-page h1 {
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 15px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 5px;
}

.event-page h2 {
    font-size: 24px;
}

.event-page .bio-section {
    display: flex;
    align-items: start;
    gap: 15px;
    margin-bottom: 20px;
    background: #ecf0f1;
    padding: 15px;
    border-radius: 10px;
}

.event-page .bio-section img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #3498db;
}

.event-page .bio-content {
    flex: 1;
    max-width: 800px;
}

.event-page .bio-content p {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 10px;
    background-color: #fafafa;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.event-page .bio-content p::-webkit-scrollbar {
    display: none;
}

.event-page .divider {
    height: 2px;
    background-color: #3498db;
    margin: 30px 0;
    border-radius: 5px;
}